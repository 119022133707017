import type { FC } from "react"
import React, { useState } from "react"
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  Spinner,
  Link,
  Flex,
} from "@chakra-ui/react"
import { BigNumber } from "ethers"
import ClassicBigNumber from "bignumber.js"

import AddressDAOSpecificVotes from "address/components/AddressDAOSpecificVotes"
import AddressCreatedProposals from "address/components/AddressCreatedProposals"
import ErrorBoundary from "common/components/ErrorBoundary"
import type {
  Governor,
  AddressMetadataQuery,
  Delegate,
  Organization,
  Token,
} from "query/graphql"
import { useAddressTabPanelsStatsQuery } from "query/graphql"
import type { ArrayElement } from "common/types/array"
import AddressReceivedDelegationsGovernance from "address/components/AddressReceivedDelegationsGovernance"
import { getWeightLabel } from "common/helpers/bignumber"
import { useDevice } from "common/hooks/useDevice"
import CardContainer from "common/components/CardContainer"
import { ROUTES } from "common/constants/routes"
import { useMe } from "user/providers/MeProvider"
import { isSameAddress, isSolanaAddress } from "web3/helpers/address"
import { addressToAccountId } from "web3/helpers/transformers"
import { getChainIdParams } from "web3/helpers/chainId"
import { WORMHOLE_TOKEN_DECIMALS } from "web3/solana/wormhole-staking/useMultigovStaking"

enum Panels {
  VOTING_POWER,
  RECEIVED_DELEGATIONS,
  PROPOSALS_CREATED,
}

type Accounts = AddressMetadataQuery["address"]["accounts"]
type Account = ArrayElement<Accounts>

type Props = {
  organization: Organization
  governors: Governor[]
  account: Account
  isWhiteLabel?: boolean
}

const AddressTabPanels: FC<Props> = ({
  organization,
  governors,
  account,
  isWhiteLabel = false,
}) => {
  const [activeTab, setActiveTab] = useState(Panels.VOTING_POWER)
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { address: voterAddress } = account

  const me = useMe()
  const { name, slug, id: orgId } = organization
  const [governor] = governors

  const { reference } = getChainIdParams((governor as any)?.chainId)

  const { data: stats, isLoading } = useAddressTabPanelsStatsQuery(
    {
      accountId: addressToAccountId(voterAddress, reference),
      input: {
        address: voterAddress,
        organizationId: orgId,
      },
      proposalsCreatedCountInput: {
        organizationId: orgId,
      },
    },
    {
      enabled:
        Boolean(voterAddress) && Boolean(governor?.id) && Boolean(reference),
    },
  )

  if (!stats) return null

  if (!stats?.delegate) {
    const showBecomeADelegateCTA =
      me && isSameAddress(me.address, voterAddress) && slug

    return (
      <CardContainer>
        <Text align="center" textStyle="body.regular.md">
          This account is not a delegate on {name}
        </Text>
        {showBecomeADelegateCTA ? (
          <Flex align="center" h={8} justify="center">
            <Link
              className="no-underline"
              href={ROUTES.governance.delegate.index(slug)}
            >
              <Text align="center" color="purple.600" textStyle="md">
                Become a delegate
              </Text>
            </Link>
          </Flex>
        ) : null}
      </CardContainer>
    )
  }

  const { quorum, token } = governor
  const weight = stats?.delegate?.votesCount
  const bigWeight = weight ? BigNumber.from(weight) : ""
  const wormholeWeight = getWeightLabel(bigWeight, WORMHOLE_TOKEN_DECIMALS)
  const weightLabel = getWeightLabel(bigWeight, token?.decimals || 0)

  const delegationsCount = stats?.delegate?.delegatorsCount

  const proposalsCreatedCount = stats?.account?.proposalsCreatedCount

  const percentOfQuorum =
    quorum !== "0" && weight
      ? new ClassicBigNumber(weight)
          .dividedBy(new ClassicBigNumber(quorum || 0))
          .multipliedBy(100)
          .toFixed(2)
      : null

  function updateCurrentTab(tab: Panels) {
    return function setTab() {
      setActiveTab(tab)
    }
  }

  return (
    <ErrorBoundary section="Address Tabs">
      {/* Display on Desktop */}
      <Tabs
        isFitted
        display={onLargeDevice}
        flexDirection="column"
        variant="unstyled"
      >
        <TabList gap={2}>
          <TabStat
            dataQa="total-voting-power-2"
            isLoading={isLoading}
            label={
              percentOfQuorum
                ? `Voting Power (${percentOfQuorum}% of Quorum)`
                : "Voting Power"
            }
            value={isSolanaAddress(voterAddress) ? wormholeWeight : weightLabel}
            onClick={updateCurrentTab(Panels.VOTING_POWER)}
          />
          <TabStat
            dataQa="received-delegations-1"
            isLoading={isLoading}
            label="Received Delegations"
            value={stats?.delegate?.delegatorsCount}
            onClick={updateCurrentTab(Panels.RECEIVED_DELEGATIONS)}
          />
          <TabStat
            dataQa="Proposals-created-1"
            isLoading={isLoading}
            label="Proposals created"
            value={stats?.account?.proposalsCreatedCount}
            onClick={updateCurrentTab(Panels.PROPOSALS_CREATED)}
          />
        </TabList>
        <TabPanels>
          <TabPanel p={0} pt={3}>
            {name ? (
              <ErrorBoundary section="AddressDAOSpecificVotes">
                <AddressDAOSpecificVotes
                  decimals={token?.decimals}
                  governors={governors}
                  isTabActive={activeTab === Panels.VOTING_POWER}
                  isWhiteLabel={isWhiteLabel}
                  organization={organization}
                  slug={slug}
                  voterAddress={voterAddress}
                />
              </ErrorBoundary>
            ) : null}
          </TabPanel>
          <TabPanel p={0} pt={1}>
            <ErrorBoundary section="AddressReceivedDelegations">
              {governor ? (
                <AddressReceivedDelegationsGovernance
                  delegate={stats?.delegate as Delegate}
                  // TODO(@nicolas): Fix this any
                  governor={governor as any}
                  isTabActive={activeTab === Panels.RECEIVED_DELEGATIONS}
                  isWhiteLabel={isWhiteLabel}
                  organization={organization}
                  voterAddress={voterAddress}
                />
              ) : null}
            </ErrorBoundary>
          </TabPanel>
          <TabPanel p={0} pt={1}>
            <ErrorBoundary section="AddressCreatedProposals">
              {governor?.id && stats ? (
                <AddressCreatedProposals
                  governors={governors}
                  isTabActive={activeTab === Panels.PROPOSALS_CREATED}
                  isWhiteLabel={isWhiteLabel}
                  organization={organization}
                  proposalsCount={stats?.account?.proposalsCreatedCount}
                  slug={slug}
                  token={token as Token}
                  voterAddress={voterAddress}
                />
              ) : null}
            </ErrorBoundary>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Display on Mobile */}
      <Tabs
        isFitted
        display={onLittleDevice}
        flexDirection="column"
        variant="unstyled"
      >
        <TabList flexDirection="column" gap={1}>
          <TabStat
            isLoading={isLoading}
            label="Voting Power"
            value={weightLabel}
            onClick={updateCurrentTab(Panels.VOTING_POWER)}
          />
          <TabStat
            isLoading={isLoading}
            label="Received Delegations"
            value={delegationsCount}
            onClick={updateCurrentTab(Panels.RECEIVED_DELEGATIONS)}
          />
          <TabStat
            isLoading={isLoading}
            label="Proposals created"
            value={proposalsCreatedCount}
            onClick={updateCurrentTab(Panels.PROPOSALS_CREATED)}
          />
        </TabList>
        <TabPanels>
          <TabPanel p={0} pt={1}>
            <ErrorBoundary section="AddressDAOSpecificVotes">
              <AddressDAOSpecificVotes
                decimals={token?.decimals}
                governors={governors}
                isTabActive={activeTab === Panels.VOTING_POWER}
                isWhiteLabel={isWhiteLabel}
                organization={organization}
                slug={slug}
                voterAddress={voterAddress}
              />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel p={0} pt={1}>
            <ErrorBoundary section="AddressReceivedDelegations">
              <AddressReceivedDelegationsGovernance
                delegate={stats?.delegate as Delegate}
                // TODO(@nicolas): Fix this any
                governor={governor as any}
                isTabActive={activeTab === Panels.RECEIVED_DELEGATIONS}
                isWhiteLabel={isWhiteLabel}
                organization={organization}
                voterAddress={voterAddress}
              />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel p={0} pt={1}>
            {/*
            <ErrorBoundary section="AddressCreatedProposals">
            {governance ? (
              <AddressCreatedProposals
              chainId={governance.chainId}
              governor={governance}
              isTabActive={activeTab === Panels.PROPOSALS_CREATED}
              isWhiteLabel={isWhiteLabel}
              proposalsCount={stats?.account?.proposalsCreatedCount}
              voterAddress={voterAddress}
              />
            ) : null}
            </ErrorBoundary>
          */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ErrorBoundary>
  )
}

type TabStatProps = {
  label?: string | number
  value?: string | number
  tabStates?: Record<string, any>
  isLoading?: boolean
  onClick: () => void
  dataQa?: string
}
const TabStat: FC<TabStatProps> = ({
  label,
  value,
  tabStates,
  isLoading,
  onClick,
  dataQa,
}) => {
  return (
    <Tab
      {...tabStates}
      _selected={{ color: "white", bg: "purple.500" }}
      alignItems="start"
      bgColor="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      height="87px"
      justifyContent="flex-start"
      p={2}
      pl={5}
      onClick={onClick}
    >
      <VStack alignItems="start" spacing={0}>
        <Text>{label}</Text>
        {isLoading ? (
          <Spinner />
        ) : (
          <Text data-qa={dataQa ? dataQa : ""} fontSize="3xl" fontWeight="bold">
            {value}
          </Text>
        )}
      </VStack>
    </Tab>
  )
}

export default AddressTabPanels
