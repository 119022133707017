import type { FC } from "react"
import React from "react"
import type { GetServerSideProps } from "next"
import { isAddress } from "@ethersproject/address"
import { Stack } from "@chakra-ui/react"

import { isSolanaAddress, shortenIfAddress } from "web3/helpers/address"
import ErrorBoundary from "common/components/ErrorBoundary"
import MainLayout from "ui/layouts/main/MainLayout"
import MainLayoutHead from "ui/layouts/main/MainLayoutHead"
import MainLayoutHeader from "ui/layouts/main/MainLayoutHeader"
import MainLayoutContainer from "ui/layouts/main/MainLayoutContainer"
import MainLayoutFooter from "ui/layouts/main/MainLayoutFooter"
import type { DescriptionException } from "proposal/types/exception"
import type {
  AddressMetadataQuery,
  Governor,
  Organization,
} from "query/graphql"
import { getAccountByAddress, getAccountByEns } from "address/helpers/fetcher"
import { fetchDescriptionExceptionsByGovernorId } from "proposal/helpers/description"
import { DescriptionExceptionsProvider } from "proposal/providers/DescriptionExceptionsProvider"
import { BREADCRUMB } from "common/constants/breadcrumb"
import type { ArrayElement } from "common/types/array"
import AddressHeader from "address/components/AddressHeader"
import AddressDelegationStatement from "address/components/AddressDelegationStatement"
import { ROUTES } from "common/constants/routes"
import AddressTabPanels from "address/components/AddressTabPanels"
import { isCorrectSlug, isWhitelabel } from "whitelabel/hooks/useIsWhiteLabel"
import {
  getWhitelabelBreadcrumb,
  getWhiteLabelRoute,
} from "whitelabel/utils/breadcrumb"
import { isValidAccountId } from "web3/helpers/accountId"
import { fetchGovernanceById } from "governance/helpers/fetch"
import { getDisplayName } from "user/helpers/user"
import {
  fetchGovernorsByOrganization,
  fetchOrganizationBySlug,
} from "organization/helpers/fetcher"
import { OrganizationProvider } from "organization/providers/OrganizationProvider"
import { OrganizationMenu } from "ui/layouts/main/header/HeaderDAO"
import DaoLayoutBreadcrumb from "ui/layouts/dao/DaoLayoutBreadcrumb"
import { getOrganizationSocialPreviewImage } from "organization/helpers/organization"
import AddressDelegatePanels from "address/components/AddressDelegatePanels"
import { shortSolAddress } from "common/helpers/string"
import { WORMHOLE_SLUGS } from "governance/components/wormhole/constants"

type Accounts = AddressMetadataQuery["address"]["accounts"]
type Account = ArrayElement<Accounts>

type Props = {
  organization: Organization
  isWhiteLabel: boolean
  governors: Governor[]
  descriptionExceptions: DescriptionException[]
  account: Account
}

const DelegatePage: FC<Props> = ({
  organization,
  isWhiteLabel,
  governors,
  descriptionExceptions,
  account,
}) => {
  const { address: voterAddress } = account

  const [governor] = governors
  const { name, slug } = organization

  const getVoterLabel = () => {
    return isAddress(displayName)
      ? shortenIfAddress(displayName)
      : isSolanaAddress(displayName)
      ? shortSolAddress(displayName)
      : displayName
  }

  const displayName = getDisplayName(account)
  const voterLabel = getVoterLabel()

  const getSocialPreview = () => {
    const title = `${voterLabel}'s Delegate Profile`
    const description = `DAO memberships, votes and proposals from ${voterLabel}`

    const image = getOrganizationSocialPreviewImage(organization)

    return { title, description, image }
  }

  function getWithGovernance(isWhiteLabel: boolean, name: string) {
    return isWhiteLabel
      ? getWhitelabelBreadcrumb(
          BREADCRUMB.profile.withGovernance(
            voterAddress,
            voterLabel,
            slug,
            name,
            isWhiteLabel,
          ),
        )
      : BREADCRUMB.profile.withGovernance(
          voterAddress,
          voterLabel,
          slug,
          name,
          isWhiteLabel,
        )
  }

  function getIndex(voterAddress: string, isWhiteLabel: boolean) {
    return isWhiteLabel
      ? getWhitelabelBreadcrumb(
          BREADCRUMB.profile.index(voterAddress, isWhiteLabel),
        )
      : BREADCRUMB.profile.index(voterAddress)
  }

  const preview = getSocialPreview()

  const breadcrumb = name
    ? getWithGovernance(isWhiteLabel, name)
    : getIndex(voterAddress, isWhiteLabel)

  const customHead = governor ? getCustomHead({ account, slug }) : undefined

  return (
    <ErrorBoundary section="Address">
      <OrganizationProvider organization={organization}>
        <DescriptionExceptionsProvider
          descriptionExceptions={descriptionExceptions}
        >
          <MainLayout>
            <MainLayoutHead
              customHead={customHead}
              preview={preview}
              title={preview.title}
            />
            <MainLayoutHeader
              activeOrganizationMenu={OrganizationMenu.DelegateProfile}
              isWhiteLabel={isWhiteLabel}
              organization={organization}
            />
            <DaoLayoutBreadcrumb breadcrumb={breadcrumb} />
            <MainLayoutContainer pt={4}>
              <Stack align="stretch" spacing={1}>
                <ErrorBoundary section="AddressHeader">
                  <AddressHeader
                    governor={governor}
                    governors={governors}
                    isWhiteLabel={isWhiteLabel}
                    organization={organization}
                    slug={slug}
                    voterAddress={voterAddress}
                  />
                </ErrorBoundary>
                <ErrorBoundary section="AddressDelegationStatement">
                  <AddressDelegationStatement
                    governorName={name}
                    governorSlug={slug}
                    organizationId={organization.id}
                    voterAddress={voterAddress}
                  />
                </ErrorBoundary>
                {governor ? (
                  <ErrorBoundary section="AddressTabPanels">
                    <AddressTabPanels
                      account={account}
                      governors={governors}
                      isWhiteLabel={isWhiteLabel}
                      organization={organization}
                    />
                  </ErrorBoundary>
                ) : null}

                {!governor ? (
                  <ErrorBoundary section="AddressDelegatePanels">
                    <AddressDelegatePanels
                      organization={organization}
                      voterAddress={voterAddress}
                    />
                  </ErrorBoundary>
                ) : null}
              </Stack>
            </MainLayoutContainer>
            <MainLayoutFooter isWhiteLabel={isWhiteLabel} mt={20} pb={16} />
          </MainLayout>
        </DescriptionExceptionsProvider>
      </OrganizationProvider>
    </ErrorBoundary>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  const { address: _address, governanceId: governanceIdParam } = query
  const voterAddressParam = isSolanaAddress(_address as string)
    ? (_address as string)
    : (_address as string).toLowerCase()
  const governanceParam = governanceIdParam as string
  const slug = governanceIdParam as string

  if (isValidAccountId(slug)) {
    const governance = await fetchGovernanceById(slug)

    if (governance && governance.organization) {
      return {
        redirect: {
          permanent: false,
          destination: isWhitelabel(context)
            ? getWhiteLabelRoute(
                ROUTES.governance.delegate.index(governance.organization.slug),
              )
            : ROUTES.governance.delegate.index(governance.organization.slug),
        },
      }
    }
  }

  if (isWhitelabel(context)) {
    if (!isCorrectSlug(context, governanceParam)) {
      return {
        redirect: {
          permanent: false,
          destination: "/404",
        },
      }
    }
  }

  const organization = await fetchOrganizationBySlug(slug)

  if (!organization) {
    return {
      redirect: {
        permanent: false,
        destination: "/404",
      },
    }
  }

  const governors = await fetchGovernorsByOrganization(organization.id)

  const isAddressParam =
    isAddress(voterAddressParam) || isSolanaAddress(voterAddressParam)

  const account = isAddressParam
    ? await getAccountByAddress(voterAddressParam)
    : await getAccountByEns(voterAddressParam)

  if (!account) {
    return {
      redirect: {
        permanent: false,
        destination: "/404",
      },
    }
  }

  // we don't want to show a solana delegate profile unless we're looking at
  // wormhole org
  if (
    !WORMHOLE_SLUGS.includes(organization.slug) &&
    isSolanaAddress(voterAddressParam)
  ) {
    return {
      redirect: {
        permanent: false,
        destination: "/404",
      },
    }
  }

  // if account was found using address, then check if this account
  // has ens to then redirect to /profile/:ens
  if (isAddressParam) {
    const ens =
      account?.ens && !isAddress(account?.ens) ? account?.ens : undefined

    if (ens) {
      return {
        redirect: {
          permanent: false,
          destination: isWhitelabel(context)
            ? getWhiteLabelRoute(
                ROUTES.governance.delegate.profile(
                  slug as string,
                  encodeURIComponent(ens),
                ),
              )
            : ROUTES.governance.delegate.profile(
                slug as string,
                encodeURIComponent(ens),
              ),
        },
      }
    }
  }

  const { descriptionExceptions } =
    await fetchDescriptionExceptionsByGovernorId(organization.governorIds)

  return {
    props: {
      organization,
      governors,
      account,
      descriptionExceptions,
      isWhiteLabel: isWhitelabel(context),
    },
  }
}

type CustomHeadProps = {
  slug: string
  account: Account
}
const getCustomHead: FC<CustomHeadProps> = ({ slug, account }) => {
  const addressOrEns = Boolean(account.ens) ? account.ens : account.address
  const profileRoute = ROUTES.governance.delegate.profile(
    slug,
    addressOrEns as string,
  )
  const delegateProfileUrl = `${process.env.NEXT_PUBLIC_TALLY_SIWE_URI}${profileRoute}`

  const imageUrl = `${process.env.NEXT_FARCARTER_FRAMES_IMAGE_URL}/api/delegate/image/frame-1?address=${addressOrEns}&governorSlug=${slug}`
  const postUrl = `${process.env.NEXT_FARCARTER_FRAMES_IMAGE_URL}/api/delegate/generate-frame?address=${addressOrEns}&currentIndex=1&governorSlug=${slug}`

  return (
    <>
      <meta content="vNext" name="fc:frame" />
      <meta content={imageUrl} name="fc:frame:image" />
      <meta content="1.91:1" name="fc:frame:image:aspect_ratio" />
      <meta content="Statement →" name="fc:frame:button:1" />
      <meta content="post" name="fc:frame:button:1:action" />
      <meta content={postUrl} name="fc:frame:post_url" />
      <meta content="Participation →→" name="fc:frame:button:2" />
      <meta content="post" name="fc:frame:button:2:action" />
      <meta content="Delegate" name="fc:frame:button:3" />
      <meta content="link" name="fc:frame:button:3:action" />
      <meta content={delegateProfileUrl} name="fc:frame:button:3:target" />
    </>
  )
}

export default DelegatePage
