import type { FC } from "react"
import React from "react"
import dayjs from "dayjs"
import { BigNumber } from "@ethersproject/bignumber"
import { getAddress } from "@ethersproject/address"
import {
  Text,
  Stack,
  Skeleton,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Badge,
  Flex,
  Wrap,
  WrapItem,
  Center,
} from "@chakra-ui/react"
import { PublicKey } from "@solana/web3.js"

import type {
  Delegation,
  ReceivedDelegationsGovernanceQueryVariables,
  ReceivedDelegationsGovernanceQuery,
  Delegate,
  GovernorMetadataQuery,
  Organization,
} from "query/graphql"
import {
  useDelegateInformationQuery,
  ReceivedDelegationsGovernanceDocument,
  DelegationsSortBy,
} from "query/graphql"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import UserIdentity from "common/components/columns/UserIdentity"
import { DATE_FORMATS } from "common/constants/date"
import { bigNumberToNumber, stringToBigNumber } from "common/helpers/bignumber"
import { useDevice } from "common/hooks/useDevice"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"
import LoadMoreButton from "common/components/LoadMoreButton"
import CardContainer from "common/components/CardContainer"
import StatBox from "common/components/StatBox"
import { isMultiChainOrganization } from "organization/helpers/organization"
import { ChainIdentity } from "common/components/ChainIdentity"
import { isSolanaAddress } from "web3/helpers/address"

type Props = {
  voterAddress: string
  isWhiteLabel?: boolean
  isTabActive: boolean
  governor: GovernorMetadataQuery["governor"]
  organization: Organization
  delegate: Partial<Delegate>
}

const AddressReceivedDelegationsGovernance: FC<Props> = ({
  voterAddress,
  isWhiteLabel = false,
  isTabActive,
  governor,
  organization,
  delegate,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const isMultiChainOrg = isMultiChainOrganization(organization)

  const PAGE_SIZE = 5

  const { data: delegateInfo, isLoading: isLoadingDelegateInfo } =
    useDelegateInformationQuery(
      {
        tokenBalancesInput: {
          organizationID: organization.id,
          address: voterAddress,
        },
      },
      { enabled: isTabActive && Boolean(organization.id) },
    )

  const {
    data: delegatorsGovernance,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteCursorQuery<
    ReceivedDelegationsGovernanceQuery,
    ReceivedDelegationsGovernanceQueryVariables
  >({
    document: ReceivedDelegationsGovernanceDocument,
    sectionName: "ReceievedDelegationsGovernance",
    nextPagePath: "delegators",
    variables: {
      input: {
        filters: {
          organizationId: organization.id,
          address: voterAddress,
        },
        sort: {
          isDescending: true,
          sortBy: DelegationsSortBy.Votes,
        },
        page: { limit: PAGE_SIZE },
      },
    },
    enabled: isTabActive && Boolean(governor?.id),
  })

  if (isLoading) {
    return (
      <Stack spacing={1}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    )
  }

  const hasDelegations =
    delegatorsGovernance?.pages &&
    delegatorsGovernance?.pages.length > 0 &&
    delegatorsGovernance?.pages[0]?.delegators?.nodes?.length > 0

  const isSelfDelegation = (address: string) =>
    isSolanaAddress(address)
      ? new PublicKey(address).equals(new PublicKey(voterAddress))
      : getAddress(address) === getAddress(voterAddress)

  if (!hasDelegations) {
    return (
      <CardContainer>
        <Flex align="center" bg="gray.50" h={16} justify="center" w="full">
          <Text textStyle="body.bold.md">No delegations to show</Text>
        </Flex>
      </CardContainer>
    )
  }

  const isActiveDelegator = (weight: string) => {
    if (weight === "0") return "gray.50"
    else return ""
  }

  const isLoadMoreDisabled = !hasNextPage || isFetchingNextPage
  const handleClickLoadMore = () => fetchNextPage()

  const delegators = delegatorsGovernance?.pages?.flatMap((page) => [
    ...(page.delegators.nodes as Delegation[]),
  ])

  const decimals = governor.token.decimals

  const tokenBalance = delegateInfo?.tokenBalances?.[0]

  return (
    <CardContainer isTableView>
      <Stack as="section">
        {!isLoadingDelegateInfo ? (
          <AddressStatBar
            totalDelegations={delegate?.delegatorsCount}
            totalTokensOwned={
              tokenBalance &&
              tokenBalance.balance &&
              typeof decimals !== "undefined" &&
              decimals >= 0
                ? bigNumberToNumber(
                    stringToBigNumber(tokenBalance?.balance),
                    decimals,
                  ).toFixed(decimals > 0 ? 2 : 0)
                : ""
            }
            votingPower={
              delegate?.votesCount &&
              typeof decimals !== "undefined" &&
              decimals >= 0
                ? bigNumberToNumber(
                    stringToBigNumber(delegate?.votesCount),
                    decimals,
                  ).toFixed(decimals > 0 ? 2 : 0)
                : ""
            }
          />
        ) : null}
        <Stack>
          <Divider />
          {/* Desktop Version */}
          <TableContainer display={onLargeDevice}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Delegator</Th>
                  <Th>Governance</Th>
                  <Th>Delegation Date</Th>
                  <Th isNumeric>Votes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {delegators?.map((delegation, index) => (
                  <Tr
                    key={index}
                    backgroundColor={isActiveDelegator(delegation.votes)}
                  >
                    <Td>
                      <UserIdentity
                        account={delegation.delegator}
                        isWhiteLabel={isWhiteLabel}
                        slug={governor?.organization?.slug}
                      />
                    </Td>
                    <Td>
                      <HStack>
                        <GovernanceAvatar
                          size={6}
                          src={organization?.metadata?.icon}
                        />
                        <Text>{organization?.name ?? ""}</Text>
                      </HStack>
                    </Td>
                    <Td>
                      {dayjs(delegation.blockTimestamp).format(
                        DATE_FORMATS.monthDayYear,
                      )}
                    </Td>
                    <Td isNumeric>
                      <HStack justifyContent="flex-end">
                        {isSelfDelegation(delegation.delegator.address) ? (
                          <Badge colorScheme="purple">Self Delegation</Badge>
                        ) : null}
                        <Text>
                          {!isLoadingDelegateInfo &&
                          delegation.votes &&
                          typeof decimals !== "undefined" &&
                          decimals >= 0
                            ? bigNumberToNumber(
                                BigNumber.from(delegation.votes),
                                decimals,
                              ).toFixed(decimals > 0 ? 2 : 0)
                            : null}
                        </Text>
                        {isMultiChainOrg ? (
                          <ChainIdentity
                            chainId={delegation.chainId}
                            height={4}
                            width={4}
                          />
                        ) : null}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {/* Mobile Version  */}
          <TableContainer display={onLittleDevice}>
            <Flex
              align="stretch"
              direction="column"
              display={onLittleDevice}
              w="full"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Delegator</Th>
                    <Th isNumeric>Votes</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {delegators?.map((delegation, index) => (
                    <Tr
                      key={index}
                      backgroundColor={isActiveDelegator(delegation.votes)}
                    >
                      <Td maxW="2em">
                        <UserIdentity
                          account={delegation.delegator}
                          slug={governor?.organization?.slug}
                        />
                      </Td>
                      <Td isNumeric>
                        <HStack justifyContent="flex-end">
                          <Text>
                            {!isLoadingDelegateInfo &&
                            delegation.votes &&
                            typeof decimals !== "undefined" &&
                            decimals >= 0
                              ? bigNumberToNumber(
                                  BigNumber.from(delegation.votes),
                                  decimals,
                                ).toFixed(2)
                              : null}
                          </Text>
                          {isMultiChainOrg ? (
                            <ChainIdentity
                              chainId={delegation.chainId}
                              height={4}
                              width={4}
                            />
                          ) : null}
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
          </TableContainer>

          {hasDelegations ? (
            <Center>
              <LoadMoreButton
                isDisabled={isLoadMoreDisabled}
                onClick={handleClickLoadMore}
              />
            </Center>
          ) : null}
        </Stack>
      </Stack>
    </CardContainer>
  )
}

export default AddressReceivedDelegationsGovernance

type AddressStatBarPropsV2 = {
  totalDelegations?: number
  totalTokensOwned?: string
  votingPower?: string
}
const AddressStatBar: FC<AddressStatBarPropsV2> = ({
  totalDelegations,
  totalTokensOwned,
  votingPower,
}) => {
  return (
    <Stack mb={6}>
      <Wrap direction={["column", "row"]} pl={6} pt={6} spacing={8}>
        <WrapItem>
          <StatBox
            dataQa="received-delegations-2"
            label="Delegations"
            size="2xl"
            value={totalDelegations}
          />
        </WrapItem>
        <WrapItem>
          <StatBox
            label="Total Tokens Owned"
            size="2xl"
            value={totalTokensOwned}
          />
        </WrapItem>
        <WrapItem flex={1}>
          <StatBox label="Voting Power" size="2xl" value={votingPower} />
        </WrapItem>
      </Wrap>
    </Stack>
  )
}
