import { HStack, Icon, Text } from "@chakra-ui/react"

import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import chains from "web3/constants/chains"
import { accountIdToChainId } from "web3/helpers/transformers"

type Props = {
  chainId: string
  includeLabel?: boolean
  width?: number
  height?: number
}

export const ChainIdentity = ({
  chainId,
  includeLabel = false,
  width = 6,
  height = 6,
}: Props) => {
  const evmChain = chainId
    ? Object.entries(chains).find(
        ([_, c]) => c.id === accountIdToChainId(chainId),
      )
    : null

  const solanaChain = Object.entries(chains).find(([_, c]) =>
    c.id.includes("solana"),
  )

  const chain = evmChain || solanaChain

  const networkIcon = chain?.[1] ? GenericNetworkIcon(chain[1]) : null

  return (
    <HStack>
      {networkIcon ? (
        <Icon as={networkIcon} height={height} width={width} />
      ) : null}
      {includeLabel ? (
        <Text fontSize="md" fontWeight="bold">
          {chain?.[1]?.name}
        </Text>
      ) : null}
    </HStack>
  )
}
